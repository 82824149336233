<template>
  <basic-card>
    <template #header>
      <div class="d-flex align-items-center justify-content-between">
        <h5>Besloten</h5>
        <material-switch 
          id="restricted-poll" 
          v-model="isRestrictedHandler"
          class="form-check form-switch mb-0 align-items-center justify-content-center"
          name="restricted" 
          :disabled="disabled"
        />
      </div>
    </template>

    <div 
      v-show="isRestricted"
      class="row"
    >
      <div class="col-6 px-0">
        <div
          class="px-2"
        >
          <h5>Gebruikers</h5>
          <user-list
            ref="userListRef"
            :user-ids="users"
            :add-user-callback="addPollUsers"
            :remove-user-callback="removePollUsers"
            :full-width="true"
            :disabled="disabled"
            :initial-max-user-count="5"
          />
        </div>
      </div>

      <div class="col-6 px-0">
        <div
          class="px-2"
        >
          <h5>Groepen</h5>
          <group-list
            ref="groupListRef"
            :group-ids="groups"
            :add-group-callback="addPollGroups"
            :remove-group-callback="removePollGroups"
            :full-width="true"
            :disabled="disabled"
            :initial-max-group-count="5"
          />
        </div>
      </div>
    </div>
  </basic-card>
</template>

<script>
import BasicCard from '@/components/UI/BasicCard.vue';
import GroupList from '@/components/Shared/GroupList/GroupList.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';
import UserList from '@/components/Projects/Edit/UserList/UserList.vue'

import { getAll as getAllUsers } from '@/api/providers/users';
import { getAllGroups } from '@/api/providers/groups';

import { toRaw } from 'vue';

import { 
  addUsers,
  removeUsers,
  addGroups,
  removeGroups
} from '@/api/providers/poll';

export default {
  components: {
    BasicCard,
    GroupList,
    MaterialSwitch,
    UserList,
  },

  props: {
    isRestricted: { type: Boolean, },
    users: { type: Array, required: true, },
    groups: { type: Array, required: true, },
    disabled: { type: Boolean, default: false, },
  },

  emits: [
    'update:isRestricted',
    'update:users',
    'update:groups'
  ],

  data() {
    return {
      userOptions: [],
      groupOptions: [],

      selectedUsers: [],
      selectedGRoups: [],

      apiUsers: [],
      apiGroups: [],

      addedUsers: [],
      removedUsers: [],

      addedGroups: [],
      removedGroups: [],
    }
  },

  computed: {
    isRestrictedHandler: {
      get() { return this.isRestricted; },
      set(value) { 
        if (this.disabled) return;
         this.$emit('update:isRestricted', value);
      }
    },
    invitedUsers() {
      let userIds = [
        ...this.users,
        ...this.addedUsers,
      ];

      return this.apiUsers.filter(
        u => userIds.filter(
          i => !this.removedUsers.includes(i)
        ).includes(u.id)
      );
    },
    invitedGroups() {
      return this.apiGroups.filter(g => this.restrictedGroupIds.includes(g.id));
    },

    selectedUserCount() {
      try {
        return this.$refs.userListRef.selectedUsers.length;
      }
      catch {
        return 0;
      }
    },

    selectedGroupCount() {
      try {
        return this.$refs.groupListRef.selectedGroups.length;
      }
      catch {
        return 0;
      }
    }
  },

  async mounted() {
    await this.getUsers();
    await this.getGroups();
    
    this.initializeSelectedUsers();
    this.initializeSelectedGroups();

    this.loading = false;
  },

  methods: {
    async getUsers() {
      try {
        let users = await getAllUsers();
        if (users == null) users = [];

        this.userOptions = users.map(u => {
          return {
            value: u.id,
            label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName ?? ''}`,
            image: u.profilePicture
          }
        });

        this.apiUsers = users;
      }
      catch {
        this.userOptions = [];
      }
    },

    initializeSelectedUsers() {
      const options = this.userOptions.filter(o => 
        (this.users ?? []).includes(Number(o.value))
      ).map(o => toRaw(o));

      for (const option of options) {
        this.selectedUsers.push({
          id: option.value,
          name: option.label,
          image: option.image
        });
      }
    },

    async getGroups() {
      try {
        let groups = await getAllGroups();
        if (groups == null) groups = [];

        this.groupOptions = groups.map(g => {
          return {
            value: g.id,
            label: g.title ?? '',
          }
        });

        this.apiGroups = groups;
      }
      catch {
        this.groupOptions = [];
      }
    },

    initializeSelectedGroups() {
      const selectedGroups = this.groupOptions.filter(g => 
        (this.groups ?? []).includes(g.id)
      );

      for (const group of selectedGroups) {
        this.selectedGroups.push(group);
      }
    },

    userDropdownValueSelectedCallback(value) {
      if (!this.addedUsers.includes(value)) {
        this.addedUsers.push(value)
      }

      this.removedUsers = this.removedUsers.filter(u => u.id != value);
    },

    groupDropdownValueSelectedCallback(value) {
      this.restrictedGroupIds = [ ...this.restrictedGroupIds, value ];
    },

    async addPollUsers(pollId, userIds) {
      await addUsers(pollId, userIds);
    },

    async removePollUsers(pollId, userIds) {
      await removeUsers(pollId, userIds);
    },
    
    async addPollGroups(pollId, groupIds) {
      await addGroups(pollId, groupIds);
    },

    async removePollGroups(pollId, groupIds) {
      await removeGroups(pollId, groupIds);
    },

    async submit(pollId) {
      await this.$refs.userListRef.submit(pollId);
      await this.$refs.groupListRef.submit(pollId);
    }
  }
}
</script>