import { getRequest, postRequest, putRequest, deleteRequest } from '..';

export function getAll() {
	return getRequest('/poll');
}

export function getById(id) {
	return getRequest(`/poll/${id}`);
}

export function createNewItem(data) {
	return postRequest('/poll', data);
}

export function updateItem(data) {
	return putRequest('/poll', data);
}

export function removeItem(id) {
	return deleteRequest(`/poll/${id}`);
}

export function addUsers(id, userIds) {
	return postRequest(
		`/poll/${id}/users`,
		userIds,
	);
}

export function removeUsers(id, userIds) {
	return deleteRequest(
		`/poll/${id}/users`,
		userIds
	);
}

export function addGroups(id, userIds) {
	return postRequest(
		`/poll/${id}/groups`,
		userIds,
	);
}

export function removeGroups(id, userIds) {
	return deleteRequest(
		`/poll/${id}/users`,
		userIds
	);
}